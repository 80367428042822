import * as React from 'react';
import { createContext, useState, useContext, ReactNode } from 'react';

interface AnimationContextProps {
    isAnimationFinished: boolean;
    setAnimationFinished: (value: boolean) => void;
}

const AnimationContext = createContext<AnimationContextProps | undefined>(undefined);

export const AnimationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAnimationFinished, setAnimationFinished] = useState(false);

    return (
        <AnimationContext.Provider value={{ isAnimationFinished, setAnimationFinished }}>
            {children}
        </AnimationContext.Provider>
    );
};

export const useAnimationContext = (): AnimationContextProps => {
    const context = useContext(AnimationContext);
    if (!context) {
        throw new Error('useAnimationContext must be used within a AnimationProvider');
    }
    return context;
};
